// @import '~@microsoft/sp-office-ui-fabric-core/dist/sass/SPFabricCore.scss';

.semTalkSvg {
  display: flex;
  padding: 10px;

  .detailsPanel {
    flex: 1;
    min-width: 30%;
    //  padding: 20px;
  }

  .svgPanel {
    flex: 1;
    //  min-width: 70%;
    //  padding: 20px;
  }

  .svgobject {
    // height: 600px;
    flex: 3;
    // background-color: white;
    // border: 1px dashed #aaa;
    float: top;
    // min-width: 70%;
    // width: 70%;
    vertical-align: top;
    /* prevents spurious border below the SVG */
  }

  .container {
    max-width: 1700px;
    width: 1700px;
    margin: 20px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
  }

  .row {
    padding: 20px;
  }
}